import React from 'react';
import StackedContainer from './components/StackedContainer';
import('./About.css')

function About() {
    const items = [
        {
            "imageSrc": "https://i.imgur.com/JZvRl8L.png",
            "text": "CypherSoftware is a dynamic duo of passionate developers based in the United Kingdom, committed to delivering cutting-edge software solutions and ensuring robust cybersecurity. With a wealth of experience in software development and a strong focus on security, we are the perfect choice to empower your business with innovative technology."
        },
        {
            "imageSrc": "https://i.imgur.com/09Eay7F.png",
            "text": "With a background in Cyber security, we comprehend the ever-growing risks of cyberattacks. Our commitment to safeguarding your software ensures that you can rely on our solutions to be secure and safe to use."
        },
        {
            "imageSrc": "https://i.imgur.com/odXNHN2.png",
            "text": "CypherSoftware offers a comprehensive suite of services, spanning from website development to full-scale infrastructures. We are your one-stop destination for all your software development needs, ensuring that your business operates at its best."
        },
        {
            "imageSrc": "https://i.imgur.com/FfA7Q60.png",
            "text": "Our small yet highly dedicated team is passionate about software development. We love tackling new challenges and pushing the boundaries of what's possible in the digital realm. When you partner with CypherSoftware, you benefit from our unwavering commitment to innovation."
        },
        {
            "imageSrc": "https://i.imgur.com/9MN01Vf.png",
            "text": "At CypherSoftware, we believe in making your business the best it can be through top-notch software solutions and robust security measures. If you're seeking a trusted partner for your software development needs, look no further. Contact us today to discuss your project and let us help you achieve your digital goals. Your success is our mission."
        }
    ]


    return (

        <StackedContainer items={items} />
    );
}

export default About;