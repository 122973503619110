import React from 'react';
import './Store.css';


import products from '../resources/products.json';
function Store() {
    return (
        <div className="store-section">
            {products.map((product, index) => (
                <div key={index} className="product-container">
                    <h3>{product.name}</h3>
                    <img className='product-image' src={product.url} alt={product.name} />
                    <p>{product.description}</p>
                    <ul>
                        <li>{product.feat_1}</li>
                        <li>{product.feat_2}</li>
                        <li>{product.feat_3}</li>
                        <li>{product.feat_4}</li>
                        <li>{product.feat_5}</li>
                    </ul>
                    <button onClick={() => window.location.href = product.button}>
                        Purchase for £{product.price}
                    </button>
                </div>
            ))}
        </div>
    );
}



export default Store;
