import React from 'react';
import ContactForm from './components/contactform';
import './Contact.css';
function Contact() {
    return (
        <div className="contact-container ">
            <h1 className="contact-title">Contact Us</h1><br></br>
            <p className="contact-text">If you have any questions, please feel free to contact us using the form below.</p>
            
            <div className="contact-form">
                <ContactForm />
            </div>
        </div>
    );
}

export default Contact;
