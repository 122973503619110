import React from 'react';
import './generic_style.css'
function Privacy() {
    return (
        <div className="legal-content ">
            <h2>Privacy Policy</h2>

            <p>Last updated: 10th October 2023</p>

            <h3>1. Introduction</h3>
            <p>At Cypher Software, we respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we handle your personal data, your privacy rights, and how the law protects you.</p>

            <h3>2. What Data We Collect</h3>
            <p>We may collect and process the following data about you:</p>
            <ul>
                <li>Name and contact details (including email address).</li>
                <li>Payment details and purchase history.</li>
                <li>Usage data (e.g., visited pages, click patterns).</li>
            </ul>

            <h3>3. How We Use Your Data</h3>
            <p>We will only use your personal data when the law allows us to. Primarily, we use your data to:</p>
            <ul>
                <li>Process and deliver your order.</li>
                <li>Manage your relationship with us (e.g., customer support, notifications).</li>
                <li>Improve our website and products.</li>
            </ul>

            <h3>4. Data Retention</h3>
            <p>We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal or accounting requirements.</p>

            <h3>5. Your Rights</h3>
            <p>You have rights under data protection laws regarding your personal data, including the right to access, correction, erasure, restriction, transfer, and objection to processing.</p>

            <h3>6. Third-Party Links</h3>
            <p>Our website may include links to third-party websites. Clicking on those links may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy policies.</p>

            <h3>7. Cookies</h3>
            <p>Our website uses cookies to improve user experience. Cookies are small data files placed on your device. You can set your browser to refuse all or some cookies or to alert you when websites set or access cookies.</p>

            <h3>8. Contact Us</h3>
            <p>If you have any questions about this privacy policy or your data protection rights, please contact us at support@cyphersoftware.uk</p>
        </div>
    );
}

export default Privacy;
