import React from 'react';

function TOS() {
    return (
        <div className="legal-content ">
            <h2>Terms of Service</h2>

            <h3>1. Acceptance of Terms</h3>
            <p>By purchasing digital products from CypherSoftware, you agree to be bound by these Terms of Service ("Terms"). If you disagree with any part of the terms, you may not access or purchase our digital products.</p>

            <h3>2. Digital Products</h3>
            <p>All products available on our platform are digital. This means there is no physical product shipped. Upon purchase, you'll receive access to the digital content as described in the product description.</p>

            <h3>3. Refund Policy</h3>
            <p>Given the nature of digital products, we generally do not offer refunds. All sales are final unless otherwise stated. If you experience technical issues or problems accessing your purchase, please contact our support team for assistance.</p>

            <h3>4. Usage and Restrictions</h3>
            <p>All digital products are for personal use only. You may not redistribute, resell, license, or offer the products for sale. Unauthorized sharing or distribution is prohibited.</p>

            <h3>5. Modifications to Terms</h3>
            <p>CypherSoftware reserves the right to change or modify these Terms at any time. It's your responsibility to review these Terms periodically. Continued use of our platform after such modifications will constitute acknowledgment and agreement of the modified terms.</p>

            <h3>6. Liability</h3>
            <p>CypherSoftware is not liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, or use, whether in an action in contract, tort (including but not limited to negligence), or otherwise, arising out of or in any way connected with the use of or inability to use our digital products.</p>

            <h3>7. Governing Law</h3>
            <p>These Terms shall be governed by and construed in accordance with the laws of The United Kingdom, without regard to its conflict of law rules.</p>

            <p>If you have questions about these Terms, please contact support@Cyphersoftware.uk.</p>
        </div>
    );
}

export default TOS;
