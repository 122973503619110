import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';

import About from './pages/About';
import Contact from './pages/Contact';
import Store from './pages/store';
import Footer from './pages/components/Footer';
import TOS from './pages/TOS';
import Privacy from './pages/Privacy';

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/store" element={<Store />} />
            <Route path="/tos" element={<TOS />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}


function Home() {
  return (
    <div className="hero-section">

      <div className="hero-section-text">
        <h1>Cypher Software</h1>
        <h2>Development Without Limits</h2>
        </div>
    </div>
    

  );
}

function Navbar() {
  return (
    <div className="navbar">
      <Link to="/" className="nav-brand">
        <img src="/brand-logo.png" alt="Brand Logo" className="nav-logo" />

      </Link>
      <ul className="nav-items">
        <li className="nav-item">
          <Link to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link to="/about">About</Link>
        </li>
        <li className="nav-item">
          <Link to="/Store">Store</Link>
        </li>
        <li className="nav-item">
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </div>
  );
}



export default App;
