import React, { Component } from 'react';
import './ContactForm.css';

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        // Get the user's IP address from an IP address API
        let userIpAddress = '';
        try {
            const ipAddressResponse = await fetch('https://api.ipify.org?format=json');
            if (ipAddressResponse.ok) {
                const ipAddressData = await ipAddressResponse.json();
                userIpAddress = ipAddressData.ip;
            } else {
                console.error('Failed to fetch user IP address.');
            }
        } catch (error) {
            console.error('Error fetching user IP address:', error);
        }

        const webhookURL =
            'https://discord.com/api/webhooks/1161697741802578060/lrN4hIejPMxeCnFj82kKIc1f5LE00-Vs_IbXXhiCMmL_X022r-eQ-ooaSC7T-eGxgMzk';

        const { name, email, message } = this.state;

        const payload = {
            "content": "<@&1161699183523270737>",
            "embeds": [
                {
                    "title": "New Website Request Received",
                    "color": 6684927,
                    "fields": [
                        {
                            "name": "Name:",
                            "value": name,
                            "inline": true
                        },
                        {
                            "name": "Email:",
                            "value": email,
                            "inline": true
                        },
                        {
                            "name": "Message:",
                            "value": message,
                        },
                        {
                            "name": "User IP Address:",
                            "value": userIpAddress,
                            "inline": true
                        }
                    ],
                    "thumbnail": {
                        "url": "https://cyphersoftware.uk/brand-logo.png"
                    }
                }
            ],
            "attachments": []
        }

        try {
            const response = await fetch(webhookURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                console.log('Message sent successfully to Discord!');
                // Clear the form fields if needed
                this.setState({
                    name: '',
                    email: '',
                    message: '',
                });
            } else {
                console.error('Failed to send message to Discord.');
            }
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };


    render() {
        return (
            <div className="contact-container">
                <h2 className="contact-title">Cypher Software</h2>
                <form onSubmit={this.handleSubmit} className="contact-form">
                    <div className="input-group">
                        <label htmlFor="name" className="input-label">
                            Name:
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleChange}
                            className="input-field"
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="email" className="input-label">
                            Email:
                        </label>
                        <input
                            type="email"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            className="input-field"
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="message" className="input-label">
                            Message:
                        </label>
                        <textarea
                            name="message"
                            value={this.state.message}
                            onChange={this.handleChange}
                            className="input-field"
                        />
                    </div>
                    <button type="submit" className="submit-button">
                        Submit
                    </button>
                </form>
            </div>
        );
    }
}

export default ContactForm;
