import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p>© 2023 Cypher Software. All rights reserved.</p>
                <a href="https://www.abuseipdb.com/user/140220" title="AbuseIPDB is an IP address blacklist for webmasters and sysadmins to report IP addresses engaging in abusive behavior on their networks">
                    <img src="https://www.abuseipdb.com/contributor/140220.svg" alt="AbuseIPDB Contributor Badge" style={{
                        width: '400px',
                        height: 'auto',
                        backgroundColor: 'white',
                        margin: '15px auto',
                        borderRadius: '5px'
                    }}/>
                </a>

                <div className="footer-links">
                    <Link to="/tos">Terms of Service</Link>
                    <span> | </span>
                    <Link to="/privacy">Privacy Policy</Link>

                </div>
            </div>


        </footer>
    );
}


export default Footer;
