import React from 'react';
import './StackedContainer.css';

const StackedContainer = ({ items }) => {
    return (
        <div className="stacked-container">
            {items.map((item, index) => (
                <div
                    key={index}
                    className={`stacked-item ${index % 2 === 0 ? 'left' : 'right'}`}
                >
                    <img src={item.imageSrc} alt={item.title} />
                    <p>{item.text}</p>
                </div>
            ))}
        </div>
    );
};

export default StackedContainer;